<template>
  <div class="news-container">
    <div class="scroll-container">
        <div class="main-container">
            <div class="left">
                <ul class="tab-container">
                <li class="tab active">模拟诊疗</li>
                </ul>
                <div class="select-box">
                    <div class="box aicon" v-if="lyList.length">领域：<div class="items"><span :class="['item', {'light': item.id == lid}]"  v-for="item in lyList" :key="item.id" @click="selectLy(item.id)">{{item.name}}</span></div></div>
                    <div class="box picon" v-if="productList.length">产品：<div class="items"><span :class="['item', {'light': item.id == pid}]" v-for="item in productList" :key="item.id" @click="selectProduct(item.id)">{{item.name}}</span></div></div>
                </div>
                <ul class="list-content"  @scroll="scrollEvent">
                <li v-for="item in list" :key="item.id" @click="goDetail(item)">
                   
                    <div class="t">
                    <p class="title" :title="item.title">{{item.title}}</p>
                    <p class="title time">{{item.releaseTime}}</p>
                    <!-- <p class="tag-wrapper">
                        <span v-show="item.realmName" class="tag">{{item.realmName}}</span>
                        <span v-show="item.productName" class="tag">{{item.productName}}</span>
                    </p> -->
                    </div>
                    <!-- <img class="img" v-if="item.titleImg" :src="item.titleImg" alt=""> -->
                </li>
                </ul>
                
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import indexApi from '@/api/index'
export default {
  name: "simulation",
  data() {
    return {
      curTab: 1,
      list: [],
      dynamicList: [],
      dynamicNewsList: [],
    
      lyList:[],
      productList:[],

      lid:'',//领域id
      pid:'', //产品id
      page:1
    }
  },
  created() {
    this.getSelectList();
    this.getSimulationVisitList();
    
  },
  mounted() {
  },
  methods: {

    // 获取资讯列表
    getSimulationVisitList() {
      let params = {
        // phone: this.form.phone
        "condition": {
          "realmId":this.lid,
          "productId": this.pid
        },
        "page": this.page,//页码
        "size": 10//页大小
      };
      indexApi.getSimulationVisitList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list;
          if(data){
                this.list = this.list.concat(data);
                this.more = res.data.pageNums > res.data.page
                let p = this.page;
                p++;
                this.page = p;
          }
          
        }
      },(err)=>{
        this.$message.error(err && err.message || '获取失败，请联系管理员！')
      })
    },

    scrollEvent(e){
        if(e.srcElement.scrollTop+e.srcElement.offsetHeight == e.srcElement.scrollHeight){
            if(this.more){
                this.getSimulationVisitList();
            }
        }
    },
    //获取筛选条件
    getSelectList(){
        let params = {};
         indexApi.getSelectTagList(params).then((res)=>{
            if(res && res.code === 1000){
            let data = res && res.data;
            this.lyList =  data.realmList || [];
            this.productList =  data.productList || [];
            }
        },(err)=>{
            this.$message.error(err && err.message || '获取失败，请联系管理员！')
        })
    },
    // 选择产品或领域
    selectLy(id){
        if(id == this.lid){
            this.lid = ''
        }else{
            this.lid = id;
        }
        this.page = 1;
        this.list = [];
        this.getSimulationVisitList();
    },
    selectProduct(id){
        if(id == this.pid){
            this.pid = ''
        }else{
            this.pid = id;
        }
        this.page = 1;
        this.list = [];
        this.getSimulationVisitList();
    },
    // 跳转详情
    goDetail(item) {
        clearInterval(this.$options.timer);
        this.$options.timer = null;
         window.open(item.path)
    },
  }
}
</script>

<style scoped lang="scss">
.news-container {
  background-image: url("~./images/bg.jpg");
  background-size: cover;
  line-height: 1;
}

.scroll-container {
  width:100%;
  max-width: 100%;
}
.main-container{
    display: flex;
    align-items: flex-start;
}
.left {
  flex: auto;
  border-radius: .22rem;
  margin-top: .6rem;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: .3rem;
    right: .3rem;
    bottom: 0;
    height: .6rem;
    background-color: #ffffff;
    box-shadow: 0 -.3rem .3rem 0 #fff;
  }
  .tab-container {
    background: rgba(255, 255, 255, 0.75);
    font-size: .18rem;
    color: #333;
    display: flex;
    align-items: center;
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    height: .7rem;
    line-height: .7rem;
    li {
      width: 2.2rem;
      position: relative;
      text-align: center;
      cursor: pointer;
      z-index: 3;

      &.active {
        color: #fff;

        &:before {
          content: '';
          display: block;
          width: 2.2rem;
          height: .88rem;
          background: url("~./images/active-tab.png") center center / 100% auto no-repeat;
          left: 0;
          bottom: 0;
          position: absolute;
          z-index: -1;
        }
      }
    }
  }

  .select-box{
      width:100%;
      background: #fff;
      padding:0.2rem 0.3rem;
      box-sizing: border-box; 
      .box{
          line-height: 0.3rem;
          margin-top:.1rem;
          display: flex;
          padding-left:0.35rem;
          &.picon{
               background: url("images/picon.png") left 1px no-repeat;
               background-size: 0.24rem 0.24rem;
          }

          &.aicon{
              background: url("images/aicon.png") left 1px  no-repeat;
              background-size: 0.24rem 0.24rem;
          }
          .items{
              flex:1;
          }
          .item{
                border-radius: 2px;
                height: 0.28rem;
                line-height: 0.28rem;
                padding: 0 0.1rem;
                margin:0  0.1rem .1rem 0;
                color: #333;
                font-size: 0.14rem;
                display: inline-block;
                position:relative;
                cursor: pointer;
                &.light{
                    background: #2DBECD;
                    color: #fff;
                }
                &::after{
                    position:absolute;
                    content: '|';
                    right:-0.07rem;
                    color:#D9D9D9;
                    height: 0.2rem;
                    overflow: hidden;
                    top:0.02rem;
                }
          }
      }
  }
  
  .list-content {
    background: #fff;
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem;
    padding: 0 .3rem 0;
    max-height: 6.6rem;
    // overflow: auto;
    height: 5.79rem;
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
    &:after{
        width: 100%;
        content: "";
        height: 0.6rem;
        display: block;
    }

    li {
      width:100%;
      margin: 0 0 .3rem;
      cursor: pointer;
    //   height: 3.5rem;
    //   border: solid 1px #f0f0f0;
    //   border-radius: .08rem;
      display: flex;
      padding-right: 0.3rem;
      .img {
        display: block;
        width: 1.8rem;
        height: 1rem;
        border-radius: .08rem;
      }
      .t {
        flex:1;
        padding: .2rem .3rem;
      }
      .title {
        font-size: .22rem;
        color: #333;
        line-height: .26rem;
        margin-bottom: .2rem;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: bold;
        &.time{
            font-size: .18rem;
            background: url("../simulation-visit/images/time.png") no-repeat left center/0.16rem 0.16rem;
            padding-left:0.2rem;
            font-weight: normal;
        }
      }
      .tag-wrapper {
        .tag {
          border-radius: 2px;
          height: .22rem;
          line-height: .22rem;
          padding: 0 .1rem;
          margin-right: .1rem;
          color: #333;
          font-size: .14rem;
          background-color: #f0f0f0;
        }
      }
    }
  }
}
.right {
  flex: none;
  margin-top: .6rem;
  width: 4.5rem;
  margin-left: .1rem;
}

</style>
